<template>
    <content-view>
        <ValidationObserver class="test" tag="div" @submit.prevent="onSubmit" ref="observer" method="post">
            <test-header
                :return-route="`/courses/${$route.params.course_id}`"
                :btn-handler="onSubmit"
                :btn-title="this.$route.params.test_id ? 'Сохранить' : 'Добавить'"
            />

            <div class="test__body">
                <div class="test__title">
                    <h1 v-if="this.$route.params.test_id">Редактирование теста</h1>
                    <h1 v-else>Добавление теста</h1>
                </div>

                <ValidationProvider rules="required|max_length:255" vid="text" v-slot="provider">
                    <div class="form-group form-group--title" :class="{'is-invalid': provider.errors.length > 0}">
                        <label for="id_text" class="form-label">Название теста *</label>
                        <textarea v-model="form.name" class="form-input form-input--title" id="id_text"/>
                        <div id="id_text-error" class="invalid-feedback animated fadeInDown"
                             v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                        </div>
                    </div>
                </ValidationProvider>

                <div class="test__question" v-for="(question, indexQuestion) in form.questions" :key="question.uuid">
                    <ValidationProvider rules="required" v-slot="provider">
                        <div class="form-group form-group--question" :class="{'is-invalid': provider.errors.length > 0}">
                            <label :for="`id_question-text-${question.uuid}`" class="form-label form-label--num">
                                <span>{{ indexQuestion + 1 }}</span> Введите<br class="no-mobile">формулировку вопроса *
                                <div class="test__question-remove-icon" @click="removeQuestion(indexQuestion)"
                                     v-if="form.questions.length > 1">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.96812 1.2037H7.82465V0.391312C7.82465 0.175219 7.64945 0 7.43333 0H4.56709C4.351 0 4.17578 0.175195 4.17578 0.391312V1.2037H2.0323C1.81621 1.2037 1.64099 1.3789 1.64099 1.59502V2.7987C1.64099 3.01479 1.81619 3.19001 2.0323 3.19001H9.96808C10.1842 3.19001 10.3594 3.01481 10.3594 2.7987V1.59502C10.3594 1.37888 10.1842 1.2037 9.96812 1.2037ZM7.042 1.2037H4.95841V0.782602H7.042V1.2037Z" fill="#989CAE"/>
                                        <path d="M2.18433 3.97266L2.50879 11.6254C2.51765 11.8348 2.69008 12.0001 2.89973 12.0001H9.10054C9.31019 12.0001 9.48262 11.8348 9.49148 11.6254L9.81593 3.97266H2.18433ZM4.81725 10.4088C4.81725 10.6249 4.64205 10.8001 4.42594 10.8001C4.20984 10.8001 4.03462 10.6249 4.03462 10.4088V5.56392C4.03462 5.34783 4.20982 5.17261 4.42594 5.17261C4.64203 5.17261 4.81725 5.34781 4.81725 5.56392V10.4088ZM6.39138 10.4088C6.39138 10.6249 6.21619 10.8001 6.00007 10.8001C5.78395 10.8001 5.60876 10.6249 5.60876 10.4088V5.56392C5.60876 5.34783 5.78395 5.17261 6.00007 5.17261C6.21619 5.17261 6.39138 5.34781 6.39138 5.56392V10.4088ZM7.96551 10.4088C7.96551 10.6249 7.79032 10.8001 7.5742 10.8001C7.35808 10.8001 7.18289 10.6249 7.18289 10.4088V5.56392C7.18289 5.34783 7.35808 5.17261 7.5742 5.17261C7.79032 5.17261 7.96551 5.34781 7.96551 5.56392V10.4088Z" fill="#989CAE"/>
                                    </svg>
                                </div>
                            </label>
                            <textarea v-model="question.text" class="form-input form-input--question"
                                      :id="`id_question-text-${question.uuid}`"/>
                            <div :id="`id_question-text-${question.uuid}-error`" class="invalid-feedback animated fadeInDown"
                                 v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                            </div>
                        </div>
                    </ValidationProvider>
                    <div class="form-group form-group--additional">
                        <label :for="`id_question-info-${question.uuid}`" class="form-label">Дополнительная информация</label>
                        <textarea v-model="question.info" class="form-input form-input--additional"
                                  :id="`id_question-info-${question.uuid}`"/>
                    </div>
                    <div class="form-group form-group--question-type">
                        <div class="test__type-wrapper">
                            <label :for="`id_question-type-${question.uuid}`" class="form-label">Тип вопроса *</label>
                            <v-select2 class="form-input form-input--question-type"
                                       :id="`id_question-type-${question.uuid}`"
                                       v-model="question.type"
                                       :reduce="name => name.id"
                                       :options="listQuestionTypes" label="name"
                                       :clearable="false"
                                       :change="changeQuestionType(question)"
                                       :searchable="false">
                                <span slot="no-options">Ничего не найдено</span>
                            </v-select2>
                        </div>
                    </div>

                    <ValidationProvider :vid="`answer-${question.uuid}-error`" tag="div" v-slot="provider">
                        <div class="form-group form-group--answer" :class="{'is-invalid': true}">
                            <legend class="form-label">Укажите варианты ответа *</legend>
                            <div class="test__answers-list">
                                <div v-for="(answer, answerIndex) in question.answers" :key="answer.uuid" class="test__answers-item">
                                    <div class="test__answers-answer" :class="{'test__answers-answer--right': answer.correct}">
                                        <label class="sr-only form-label" :for="`answer_${answer.uuid}`">{{ answerIndex + 1 }} вариант ответа</label>
                                        <div class="test__input-wrapper">
                                            <input
                                                type="text"
                                                :id="`answer_${answer.uuid}`"
                                                class="test__answer-input form-input"
                                                :class="{'test__answer-input--pr':question.answers.length > 2}"
                                                v-model="answer.text"
                                            />
                                            <div class="test__answers-btns">
                                                <i class="test__answers-btn test__answers-btn--select"  @click="setAnswer(answer, question)"></i>
                                                <i v-if="question.answers.length > 2" class="test__answers-btn test__answers-btn--del" @click="removeAnswer(question.answers, answerIndex)"></i>
                                            </div>
                                        </div>
                                        <div class="test__right-answer-score" v-if="answer.correct">
                                            <div class="test__right-answer-score-wrapper">
                                                <input v-model="answer.score"
                                                       maxlength="3"
                                                       class="test__right-answer-score-input form-input" type="text"
                                                       @change="scoreAnswerChange(answer)"
                                                       v-mask="{regex: '^([0-9]*)?$', greedy: false}"
                                                       onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"
                                                       :id="`id_answer-score-${answer.uuid}`">
                                                <label :for="`id_answer-score-${answer.uuid}`"
                                                       class="test__right-answer-score-label form-label">
                                                    {{ scoreText(answer.score) }} за <br>правильный ответ
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    v-if="question.answers.length < 10"
                                    class="test__answer-add"
                                    href="javascript:void(0)"
                                    @click="addAnswer(question.answers)"
                                >+ Добавить вариант ответа</a>
                                <div :id="`answer-${question.uuid}-error`" class="invalid-feedback animated fadeInDown"
                                     v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                </div>
                            </div>
                        </div>
                    </ValidationProvider>
                </div>

                <div class="test__panel">

                    <div class="form-group form-group--test-add">
                        <a class="test__add-question-btn v-btn" href="javascript:void(0)" @click="addQuestion">
                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="33" height="33" rx="16.5" fill="#FFB71C"/>
                                <path d="M17 11V21" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H22" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Добавить вопрос
                        </a>
                    </div>
                    <ValidationProvider rules="required" v-slot="provider">
                        <div class="form-group" :class="{'is-invalid': true}">
                            <label class="test__label test__label--score form-label" for="id_test-score">Баллы для прохождения теста</label>
                            <div class="test__input-row">
                                <input class="test__input form-input form-input--score"
                                       @change="scoreChange"
                                       v-mask="{regex: '^([0-9]*)?$', greedy: false}"
                                       onkeypress="return event.key === 'Enter' || (Number(event.key) >= 0 && Number(event.key) <= 9)"
                                       v-model="form.score" type="text" name="test-score" maxlength="8" id="id_test-score">
                                <span class="test__input-info">Сейчас можно набрать<br> {{ totalScoreText }}</span>
                                <div id="id_test-score-error" class="invalid-feedback animated fadeInDown"
                                     v-if="provider.errors.length > 0"> {{provider.errors[0]}}
                                </div>
                            </div>
                        </div>
                    </ValidationProvider>

                </div>
            </div>

        </ValidationObserver>
    </content-view>
</template>

<script>
import session from "@/api/session";

export default {
    name: "TestChange",
    components: {
        ContentView : () => import('@/views/menu/ContentView'),
        TestHeader : () => import('@/components/course/TestHeader')
    },
    data() {
        return {
            id: {
                course: this.$route.params.course_id
            },
            form: {
                name: '',
                score: 0,
                max_score: 0,
                questions: [
                    {
                        uuid: this.$uuid.v1(),
                        text: '',
                        info: '',
                        type: 0,
                        answers: [
                            {
                                uuid: this.$uuid.v1(),
                                id: 1,
                                text: '',
                                score: 0,
                                correct: false
                            },
                            {
                                uuid: this.$uuid.v1(),
                                id: 2,
                                text: '',
                                score: 0,
                                correct: false
                            }
                        ],
                    }
                ]
            },
            listQuestionTypes: [
                {
                    id: 0,
                    name: 'Один правильный ответ'
                },
                {
                    id: 1,
                    name: 'Несколько правильных ответов'
                }
            ],
            activeValid: false
        }
    },
    async beforeCreate () {
        try {
            const request = session.get(`/api/v1/course-test/access_change/`);
            const response = await request;
        } catch (error) {
            this.$router.push('/403');
        }
    },
    async created () {
        if (this.$route.params.test_id) {
            try {
                let request = session.get(`/api/v1/course-test/${this.$route.params.test_id}/`);
                let response = await request;
                response.data.questions = response.data.questions.map(question => ({
                    ...question,
                    uuid: this.$uuid.v1()
                }))
                this.form = response.data;
            } catch (err) {
            }
        }

    },
    mounted(){
        this.$scrollTo('.content-view')
    },
    computed: {
        totalScoreText() {
            let residue = this.form.max_score % 10;
            let text = this.scoreText(residue)
            return `${this.form.max_score} ${text}`;
        },
    },
    watch: {
        'form.questions': {
            handler: function (val, oldVal) {
                if (this.activeValid) {
                    this.validAnswer()
                }
            },
            deep: true
        }
    },
    methods: {
        totalScore() {
            let total = 0;
            this.form.questions.forEach(question => {
                question.answers.forEach(answer => {
                    total += Number(answer.score)
                })
            });
            this.form.max_score = total;
        },
        scoreChange() {
            if (!this.form.score) {
                this.form.score = 0
            } else if (this.form.score > this.form.max_score) {
                this.form.score = this.form.max_score
            }
        },
        scoreAnswerChange(answer) {
            if (!answer.score) {
                answer.score = 0
            }
            this.totalScore();
        },
        scoreText(score) {
            let text = ''
            let residue = score % 10
            if (residue === 1) {
                text = 'балл';
            } else if (residue > 1 && residue < 5) {
                text = 'балла';
            } else {
                text = 'баллов';
            }
            return text
        },
        addQuestion() {
            this.form.questions.push({
                uuid: this.$uuid.v1(),
                text: '',
                info: '',
                type: 0,
                answers: [
                    {
                        uuid: this.$uuid.v1(),
                        text: '',
                        score: 0,
                        correct: false
                    },
                    {
                        uuid: this.$uuid.v1(),
                        text: '',
                        score: 0,
                        correct: false
                    }
                ]
            })
        },
        removeQuestion(index) {
            this.form.questions.splice(index, 1);
            this.totalScore();
        },
        addAnswer(answers) {
            answers.push({
                uuid: this.$uuid.v1(),
                text: '',
                score: 0,
                correct: false
            })
        },
        changeQuestionType(question) {
            //Если было выбрано несколько правильных ответов, то сбросить их при выборе типа "Один правильный ответ"
            if (question.type === 0 && question.answers.filter(answer => answer.correct).length >= 2) {
                question.answers.forEach(answer => {
                    answer.correct = false;
                })
            }
        },
        setAnswer(answer, question) {
            //Если тип ответа "Несколько правильных ответов" или не выбран правильный ответ
            if (question.type === 1 || !question.answers.some(answer => answer.correct)) {
                answer.correct = !answer.correct;
                answer.score = 0;
            } else {
                if (answer.correct) {
                    answer.correct = !answer.correct;
                }
            }
        },
        removeAnswer(answers, index) {
            answers.splice(index, 1);
            this.totalScore();
        },
        async validAnswer() {
            let isValid = await this.$refs.observer.validate();

            function unique(arr) {
                let result = [];
                for (let str of arr) {
                    if (!result.includes(str)) {
                        result.push(str);
                    }
                }
                return result;
            }

            this.form.questions.forEach((question) => {
                let count_score = 0;
                let count_checkbox = 0;
                let text_repeat = [];
                let error = false;
                question.answers.map((answer) => {
                    if (answer.text.trim() === '') {
                        this.$refs.observer.setErrors({
                            [`answer-${question.uuid}-error`]: 'Заполните вариант ответа.'
                        });
                        error = true;
                        isValid = false;
                    }
                    if (answer.correct) {
                        count_checkbox += 1;
                    }
                    if (answer.score > 0) {
                        count_score += 1;
                    }
                    question.answers.map((answer_repeat) => {
                        if (answer.text === answer_repeat.text && answer.uuid !== answer_repeat.uuid) {
                            text_repeat.push(answer.uuid, answer_repeat.uuid)
                        }
                    });
                });

                if (question.type === 0 && count_checkbox !== 1 && !error) {
                    this.$refs.observer.setErrors({
                        [`answer-${question.uuid}-error`]: 'Выберите правильный ответ.'
                    });
                    error = true;
                    isValid = false;
                }

                if (question.type === 1 && count_checkbox < 2 && !error) {
                    this.$refs.observer.setErrors({
                        [`answer-${question.uuid}-error`]: 'Выберите несколько правильных ответов.'
                    });
                    error = true;
                    isValid = false;
                }

                if (count_score < count_checkbox && !error) {
                    this.$refs.observer.setErrors({
                        [`answer-${question.uuid}-error`]: 'Укажите баллы за правильный ответ'
                    });
                    error = true;
                    isValid = false;
                }

                if (text_repeat.length > 0 && !error) {
                    unique(text_repeat).map((uuid) => {
                        this.$refs.observer.setErrors({
                            [`answer-${question.uuid}-error`]: 'Введите разные ответы.'
                        });
                    });
                    error = true;
                    isValid = false;
                }
            });
            return isValid;
        },
        async onSubmit() {
            let isValid = await this.$refs.observer.validate();
            isValid = await this.validAnswer();
            this.activeValid = true;
            if (isValid) {
                try {
                    this.form.module = this.$route.params.module_id;
                    if (this.$route.params.test_id) {
                        let request = session.put(`/api/v1/course-test/${this.$route.params.test_id}/`, this.form);
                        let response = await request;
                    } else {
                        let request = session.post('/api/v1/course-test/', this.form);
                        let response = await request;
                    }
                    this.$router.push(`/courses/${this.$route.params.course_id}`);
                    this.$swal({
                        title: 'Отлично!',
                        text: this.$route.params.test_id ? 'Тест изменен' : 'Тест добавлен',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1400,
                    });
                } catch (err) {
                    if (err.response) {
                        this.$refs.observer.setErrors(err.response.data);
                    }
                }
            } else {
                this.$nextTick(() => {
                    const element = document.querySelector('.invalid-feedback');
                    if (element) {
                        this.$scrollTo(element, {offset: -300});
                    }
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.test::v-deep {
    .vs__dropdown-toggle {
        min-height: 38px;
        max-height: 38px;
        border-radius: 2px;
    }
}

.test{
    color: $black;
    @include below($tablet) {
        position: relative;
    }

    &__title{
        padding: 30px 0;

        h1{
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 22px;
        }
    }

    &__body{
        width: 100%;
        margin: 100px auto 50px;
        padding: 0 100px;

        @include below($tablet) {
            padding: 0px 40px;
            margin-bottom: 0;
            margin-top: 0;
        }

        @include below($mobile) {
            padding: 0px;
        }
    }

    &__question{
        margin-top: 30px;
        margin-bottom: 45px;
    }

    &__panel{
        padding-top: 10px;
        @include below($tablet) {
            padding-bottom: 120px;
        }

        @include below($mobile) {
            padding-bottom: 105px;
        }
    }

    &__answer {

        &-input--pr {
            padding-right: 60px !important;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .form{
        &-group{

            &--question{
                .form-input{
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            &--time{
                margin: 0;
            }

            &--question-type {
                width: 100%;
                display: flex;
                align-items: flex-start;

                @include below($mobile) {
                    align-items: flex-start;
                    flex-direction: column;
                }
            }

            &--test-add{
                margin-bottom: 30px;

                @include below($mobile) {
                    margin-bottom: 18px;
                }
            }
        }

        &-testing{
            padding-top: 26px;
        }

        &-label{
            color: #9F9F9F;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 1;
            margin-bottom: 7px;
        }

        &-label--num{
            display: flex;
            align-items: flex-end;
            line-height: 18px;
            position: relative;

            span{
                font-style: normal;
                font-weight: bold;
                font-size: 46px;
                line-height: 35px;
                text-align: right;
                color: #E6E6E6;
                margin-right: 5px;

                @include below($mobile) {
                    font-size: 28px;
                    line-height: 1;
                }
            }
        }

        &-input{
            display: block;
            width: 100%;
            padding: 8px 31px 11px 8px;
            border: 1px solid #D5DBE4;
            border-radius: 2px;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
            outline: 0;


            &--title{
                min-height: 78px;
            }

            &--question-type{
                border: none;
                padding: 0;
            }

            &--score{
                font-weight: 600;
            }
        }
    }

    &__question-remove-icon{
        position: absolute;
        bottom: 0;
        right: 0;
        border: 2px solid #E6EBF3;
        padding: 5px 8px;
        border-radius: 50%;
        cursor: pointer;

        @include below($tablet) {
            padding: 4px 7px;
        }

        svg{
            margin-top: -4px;
        }
    }

    &__answers{
        &-list{
            margin-bottom: 10px;
        }

        &-item{

        }

        &-answer{
            padding-top: 10px;
        }

        &-answer--right{
            display: flex;
            align-items: center;

            @include below($mobile) {
                align-items: flex-start;
                flex-direction: column;
            }

            .test__answers-btn--select {
                background-image: $right-active-answer;
            }
        }

        &-btns {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);

            display: flex;
            align-items: center;
        }

        &-btn {
            display: flex;
            align-items: center;
            width: 100%;
            position: relative;

            margin-left: 10px;

            &--select {
                background-image: $right-answer;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 15px;
                width: 15px;
                cursor: pointer;
            }

            &--del {
                background-image: $plus-icon;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }
    }

    &__type{
        &-wrapper{
            width: 61.7%;
            flex-shrink: 0;

            @include below($tablet) {
                width: 69.6%;
            }

            @include below($mobile) {
                width: 100%;
            }
        }

        &-time{
            width: 100%;
            margin-left: 15px;

            @include below($mobile) {
                margin-left: 0;
            }
        }

        &-time-input{
            height: 38px;
        }
    }

    &__right-answer-score {
        margin-left: 15px;

        @include below($mobile) {
            margin-left: 0;
            margin-top: 10px;
            margin-bottom: 15px;
        }


        &-wrapper{
            display: flex;
            align-items: flex-end;
            padding: 0;
            height: 38px;
        }

        &-input{
            flex-shrink: 0;
            width: 60px!important;
            // height: 38px;
            padding: 8px 11px;
            margin-right: 8px;
        }

        &-label{
            position: static !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #9F9F9F;
        }
    }

    &__add-question-btn {
        padding-left: 0;

        background-color: transparent;

        svg {
            margin-right: 12px;

            rect {
                transition: fill 0.3s;
            }
        }

        &:hover,
        &:focus {
            background-color: transparent;

            svg rect {
                fill: #FFC956;
            }
        }

        @include below($mobile) {
            margin-bottom: 10px;
        }
    }

    &__answer-add {
        display: flex;
        justify-content: center;
        align-items: center;

        // width: 100%;
        width: 61.7%;
        height: 39px;
        margin-top: 10px;

        border: 1px dashed #D5DBE4;
        border-radius: 2px;

        font-weight: 600;
        font-size: 15px;
        line-height: 20px;

        color: #000;

        @include below($tablet) {
            width: 69.6%;
        }

        @include below($mobile) {
            width: 100%;
        }
    }

    &__label {
        position: static;

        &--score {
            max-width: 190px;
            margin-bottom: 10px;
        }
    }

    &__input {
        max-width: 208px;
        margin-right: 16px;

        @include below($mobile) {
            margin-bottom: 10px;
        }
    }

    &__input-wrapper {
        position: relative;

        flex-shrink: 0;

        width: 61.7%;

        @include below($tablet) {
            width: 69.6%;
        }

        @include below($mobile) {
            width: 100%;
        }
    }

    &__input-info {
        max-width: 190px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #9F9F9F;
    }

    &__input-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    legend{
        margin-bottom: 0!important;
    }

    .no-mobile{
        @include below($mobile) {
            display: none;
        }
    }
}

</style>
